import React from 'react';
// material ui
import { makeStyles,createStyles } from '@material-ui/core/styles';

const style = makeStyles((theme) => createStyles({
  footStyle: {
    width:'100%',
    backgroundColor:'#342b38',
    padding:'2vw 0',
		marginTop:'10vw',
  },
  inner: {
		width:'80%',
		margin:'0 auto'
  },
  pStyle:{
    color:'#fff'
  },
}));


function FooterCom(props){
  const classes = style();
  return(
    <div className={classes.footStyle}>
      <div className={classes.inner}>
        <p className={classes.pStyle}>©︎2020 satsuki sugama</p>
      </div>
    </div>
  )
}

export default FooterCom;