import React from 'react';
// material ui
import { makeStyles,createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import DetailDialog from './dialog';

const style = makeStyles((theme) => createStyles({
	h2Style: {
		fontSize: '4vw'
	},
	itemTexts: {
		paddingLeft: '5px'
	},
	itemText: {
		textAlign: 'left',
		marginBottom: '13px',
		listStyle:'none'
	},
	itemTextTitle: {
		fontWeight: 'bold',
		width: '20px',
		color: '#008080'
	},
	itemImg: {
		width: '100%',
		marginBottom: '-5px',
		background: '#000',
		cursor: 'pointer',
		transitionDuration: '0.5s',
		'&:hover': {
			transform: 'scale(1.1)',
			transitionDuration: '0.5s',
			opacity: 0.4
		}
	},
	contentItemStyle: {
		marginTop: '0'
	},
	bottomBarStyle:{
		position:'absolute',
		bottom:0,
		left:0,
		backgroundColor: 'rgba(52,43,56,0.9)',
		color:'#fff',
		marginTop:0,
		textAlign: 'left',
		paddingLeft:'1.6vw',
		width: '100%'
	},
	barTextStyle:{
		fontSize:'1rem',
	},
	detailContentStyle:{
		color:'#342b38'
	},
	workContentStyle:{
		padding:'2vw auto 10vw auto'
	},
	paperStyle:{
		overflow: 'hidden',
		backgroundColor: '#000',
		borderRadius:'0px',
		position: 'relative'
	},
}));


/* 掲載コンテンツ */
const workItems = [
	{
		title:'ロボホンおしゃべりアプリ(管理画面)',
		category:'Webアプリ',
		text:[['言語・環境','Java(サーブレット、JSP)、AWS、Windows'],
					['機能','Web上で、ロボホンにしゃべってほしい文言をデータベースに登録、編集、削除できる'],
					['携わった工程','全行程（画面レイアウト含む）'],
				],
		img:'robot.jpg',
		url:'',
		previewSrc:''
	},
	{
		title:'教室のホームページ制作',
		category:'ホームページ',
		text:[['言語・環境','html、css、php、JavaScript、Wordpress、mac'],
					['機能','子どもプログラミング教室の紹介、教室の様子等を見ることができる'],
					['携わった工程','全行程（画面レイアウト含む）']
				],
		img:'img2.jpg',
		url:'https://mokumokupg.sigh.ltd/'
	},
	{
		title:'ロボホンおしゃべりアプリ(リモコン)',
		category:'iOSアプリ',
		text:[['言語・環境','Swift'],
					['機能','iPhoneをロボホンと接続し、管理画面で登録した内容を選択すると、ロボホンがその文言をしゃべってくれる'],
					['携わった工程','全行程（画面レイアウト含む）']
				],
		img:'robot2.jpg',
		url:'',
		previewSrc:''
	},
	{
		title:'特集ページの作成',
		category:'Webサイト',
		text:[['言語・環境','html、css、php、JavaScript'],
					['機能','ECサイトで販売している商品の紹介や、キャンペーン内容を見ることができる'],
					['携わった工程','全行程（LP制作は別作業者）']
				],
		img:'img3.jpg',
		url:'https://e-shop.urawa-reds.co.jp/special/reds2020_porter',
	},
	{
		title:'子どもプログラミング教室教材作成',
		category:'Webサイト',
		text:[['言語・環境','html、css、JavaScript、ムードル'],
					['機能','Pythonの基本や簡易ゲーム作り、html・cssの基礎学習や簡易ホームページ作り、Scratchを利用したゲーム作り等の学習ができる'],
					['携わった工程','全行程（画面レイアウト含む）']
				],
		img:'book1.jpg',
		url:'',
		previewSrc:''
	},
	{
		title:'ポートフォリオ',
		category:'Webサイト',
		text:[['言語・環境','JavaScript(React)、mac'],
					['機能','React.jsを使って、作成したポートフォリオ。(ステートやフック、propsなどの理解が深まった。)'],
					['携わった工程','全行程']
				],
		img:'img1.jpg',
		url:'/',
		previewSrc:''
	},
	{
		title:'訪日顧客案内アプリ',
		category:'iOSアプリ',
		text:[['言語・環境','Swift、mac'],
					['機能','駅員さんが利用するアプリで、入力された外国語や、シチュエーションごとによく使われそうなフレーズを選択し翻訳することができる'],
					['携わった工程','画面作成、取得データの表示、翻訳機能']
				],
		img:'bullet_train1.jpg',
		url:'',
		previewSrc:''
	},
]


/********************************
 *** 経歴を表示するコンポーネント ****
 ********************************/
function ContentItems(props){
	const classes = style();

	// フック
	const [dialogState, setOpen] = React.useState({open:false, item:{title:'', text:[], category:''}});

	// 閉じる処理
	const handleOpen = (i) => {
		setOpen({open:true, item:workItems[i]});
	};

	// 閉じる処理
	const handleClose = () => {
		setOpen({open:false, item:{title:'', text:[], category:''}});
	};

	return(
		<div id="work" className={classes.workContentStyle}>
			<SubTitle text='WORK'/>
			<Grid container spacing={2}>
				{workItems.map((item, i) => (
					<React.Fragment key={i}>
					<Grid item xs={12} sm={4} className={classes.contentItemStyle}>
						{/* ▼▽▼ アイテム ▼▽▼ */}
						<Paper className={classes.paperStyle} onClick={()=> handleOpen(i)}>
							<img src={item.img} className={classes.itemImg}/>
							<BottomBer title={item.title}/>
						</Paper>
						{/* ▲△▲ アイテム ▲△▲ */}
					</Grid>
					</React.Fragment>
				))}
			</Grid>
			<DetailDialog open={dialogState.open} onClose={handleClose} item={dialogState.item}/>
		</div>
	)
}

function ContentItems2(props){
	const classes = style();
	return(
		<div>
			<SubTitle text='SKILL' />
			<Grid container spacing={0}>
			</Grid>
		</div>
	)
}


/********************************
 ********** サブタイトル **********
 ********************************/
function SubTitle(props){
	const classes = style();
	return(
		<h2 className={classes.h2Style}>{props.text}</h2>
	)
}

/********************************
 ******** アイテムの下のバー *******
 ********************************/
function BottomBer(props){
	const classes = style();
	return(
		<div className={classes.bottomBarStyle}>
			<p className={classes.barTextStyle}>{props.title}</p>
		</div>
	)
}


export default ContentItems;