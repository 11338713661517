import React from 'react';
// material ui
import { makeStyles,createStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
// アイコン
import CancelPresentationTwoToneIcon from '@material-ui/icons/CancelPresentationTwoTone';

import {SquareBtn} from './btn';

const style = makeStyles((theme) => createStyles({
	detailContentStyle:{
		color:'#342b38'
	},
	closeIconStyle:{
		marginLeft: 'auto',
		color: '#342b38',
		'&:hover':{
			color:'#ff9595'
		}
	},
	detailTitleWrapStyle:{
		borderBottom: 'solid 2px #342b38',
		marginBottom: '10px',
		paddingTop: '5px'
	},
	detailTitleStyle:{
		color:'#342b38',
		fontWeight:'bold',
		margin: 0
	},
	categoryStyle:{
		backgroundColor:'#ff9595',
		padding:'1px 5px',
		fontSize:'0.7rem',
		color:'#342b38',
		fontWeight: 'bold',
		border:'2px solid #342b38',
		borderRadius:'3px',
		display: 'inline-block',
		margin: '2px 10px',
		marginRight:'auto'
	},
	detailSubtitleStyle:{
		marginBottom:0,
		color: '#342b38',
		fontWeight: 'bold',
		margin:0
	},
	detailTextStyle:{
		marginTop: '3px'
	}
}))


/********************************
 ******* 詳細表示ダイアログ ********
 ********************************/
function DetailDialog(props){
	const classes = style();
	console.log()

	return(
		<Dialog open={props.open} onClose={props.onClose} aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description" className={classes.detailContentStyle}
		maxWidth='xs' fullWidth={true} scroll='paper'>
			<DialogActions>
				<p className={classes.categoryStyle}>{props.item.category}</p>
				<CancelPresentationTwoToneIcon onClick={props.onClose} className={classes.closeIconStyle}/>
			</DialogActions>
			<DialogTitle id="max-width-dialog-title" className={classes.detailTitleWrapStyle}>
				<p className={classes.detailTitleStyle}>{props.item.title}</p>
			</DialogTitle>
			{props.item.text.map((texts, i) => (
				<DialogContent>
					<DialogContentText>
						<p className={classes.detailSubtitleStyle}>{texts[0]}</p>
						<p className={classes.detailTextStyle}>{texts[1]}</p>
					</DialogContentText>
				</DialogContent>
			))}
			<SquareBtn text='DETAIL' url={props.item.url}/>
		</Dialog>
	)
}


export default DetailDialog;