import React from 'react';
import { makeStyles,createStyles } from '@material-ui/core/styles';


const style = makeStyles((theme) => createStyles({
	sqBtnStyle:{
		width:'100%',
		color:'#fff',
		backgroundColor:'#ff9595',
		display:'block',
		padding: '15px 0',
    textAlign: 'center',
		fontWeight:'bold',
		'&:hover': {
			backgroundColor:'rgba(255,149,149,0.7)',
			color:'#fff',
		}
	},
	clBtnStyle:{
		color:'#342b38',
		fontSize:'1.5rem',
		fontWeight:'bold'
	}
}));

function SquareBtn(props){
	const classes = style();

	return(
		<div>
			{props.url == '' ? 
				<a className={classes.sqBtnStyle} style={{backgroundColor:'#625c66'}}>No Detail</a>
				:<a className={classes.sqBtnStyle} href={props.url} target='_blank'>Detail</a>
			}
		</div>
	)
}

function CloseBtn(props){
	const classes = style()

	return(
		<div>
			<a className={classes.clBtnStyle}>✖️ CLOSE</a>
		</div>
	)
}

export {SquareBtn, CloseBtn}