import React from 'react';
// material ui
import { makeStyles,createStyles } from '@material-ui/core/styles';
// アイコン
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';

import ContentItems from './items';
import FooterCom from './footer';

const style = makeStyles((theme) => createStyles({
	root: {
		fontFamily:'Trebuchet MS,sans-serif',
		color:'#342b38',
		width:'100%',
		backgroundColor:'#ff9595',
		paddingTop:'30px',
		textAlign:'center'
	},
	inner: {
		width:'80%',
		margin:'0 auto'
	},
	headStyle:{
		paddingTop: '11vw',
		paddingBottom: '20vw'
	},
	h1Style: {
		color:'#fff',
		fontSize: '11.5vw',
    margin: '0 auto 20px auto'
	},
	pStyle: {
		margin: 0,
    fontSize: '2vw',
    fontWeight: 'bold',
    marginTop: '5vw',
    color: '#fff'
	},
	arrowStyle: {
		fontSize: '6vw',
    color: '#fff',
		marginTop: '2vw',
		transitionDuration: '0.5s',
		'&:hover': {
			color:'rgba(255, 255, 255, 0.6)',
			transitionDuration: '0.5s',
		}
	},
}));


// ページ
function TopPage(){
	const classes = style();
	return(
		<div className={classes.root}>
			<div className={classes.inner}>
				<TopBnr />
				<ContentItems />
			</div>
			<FooterCom />
		</div>
	)
}

/********************************
 ********** トップの文字 **********
 ********************************/
function TopBnr(props){
	const classes = style();
	return(
		<div className={classes.headStyle}>
			<h1 className={classes.h1Style}>Welcome to my portfolio</h1>
			<p className={classes.pStyle}>すがまさつきのポートフォリオ</p>
			<a href="#work">
				<ArrowDownwardRoundedIcon className={classes.arrowStyle} />
			</a>
		</div>
	)
}

export default TopPage;